import {
  createUserWithEmailAndPassword,
  getAuth,
  onAuthStateChanged,
  signInAnonymously,
  signInWithEmailAndPassword,
} from "firebase/auth";
import { useEffect, useRef, useState } from "react";

import styles from "./SignIn.module.css";

const SignIn = () => {
  const auth = getAuth();
  const emailRef = useRef("");
  const passwordRef = useRef("");

  const [activeTab, setActiveTab] = useState("Register");
  const [user, setUser] = useState(auth?.currentUser?.uid);
  useEffect(() => {
    if (document.cookie.includes("LoggedInBefore=true")) setActiveTab("Login");
    onAuthStateChanged(auth, (u) => {
      setUser(u?.uid);
    });
  }, [auth]);

  const clearValidity = () => {
    emailRef.current.setCustomValidity("");
    passwordRef.current.setCustomValidity("");
  };

  const loginHandler = async (e) => {
    e.preventDefault();

    const email = emailRef.current.value;
    const password = passwordRef.current.value;

    if (!email.match(/\S+@\S+\.\S+/)) {
      emailRef.current.setCustomValidity("Invalid e-mail!");
      e.target.reportValidity();
      return;
    }
    if (password.length < 8) return null;
    const expires = new Date("9999-12-31").toUTCString();
    document.cookie = `LoggedInBefore=true;expires=${expires}`;
    switch (activeTab) {
      case "Register":
        try {
          await createUserWithEmailAndPassword(auth, email, password);
        } catch (error) {
          console.log(error.code);
          switch (error.code) {
            case "auth/email-already-in-use":
              emailRef.current.setCustomValidity("Email already in use");
              break;

            default:
              emailRef.current.setCustomValidity("Something went wrong!");
              break;
          }
          e.target.reportValidity();
        }
        break;
      case "Login":
        try {
          await signInWithEmailAndPassword(auth, email, password);
        } catch (error) {
          console.log(error.code);
          switch (error.code) {
            case "auth/wrong-password":
              passwordRef.current.setCustomValidity("Wrong password!");
              emailRef.current.setCustomValidity("");
              break;
            case "auth/user-not-found":
              emailRef.current.setCustomValidity("No user with this email");
              break;
            case "auth/too-many-requests":
              emailRef.current.setCustomValidity(
                "Too many attempts, try again later"
              );
              break;
            default:
              emailRef.current.setCustomValidity("Something went wrong!");
              break;
          }
          e.target.reportValidity();
        }
        break;
      default:
        break;
    }
  };

  const activeTabHandler = (tab) => {
    emailRef.current.setCustomValidity("");
    passwordRef.current.setCustomValidity("");

    setActiveTab(tab);
  };

  if (user) return null;
  return (
    <form className={styles.Form} onSubmit={loginHandler} method="post">
      <div className={styles.Inputs}>
        <div className={styles.Tabs}>
          <div
            onClick={() => activeTabHandler("Register")}
            className={`${styles.Tab} ${
              activeTab === "Register" ? styles.Selected : ""
            }`}
          >
            <h1>Register</h1>
          </div>
          <div
            onClick={() => activeTabHandler("Login")}
            className={`${styles.Tab} ${
              activeTab === "Login" ? styles.Selected : ""
            }`}
          >
            <h1>Login</h1>
          </div>
        </div>
        <div className={styles.Input}>
          <label style={{ marginRight: "1em" }} htmlFor="mail">
            Email
          </label>
          <input
            name="mail"
            type="email"
            ref={emailRef}
            onChange={clearValidity}
            required
          />
        </div>
        <div className={styles.Input}>
          <label htmlFor="pwd">Password</label>
          <input
            name="pwd"
            type="password"
            minLength={8}
            ref={passwordRef}
            onChange={(e) => e.target.setCustomValidity("")}
            required
          />
        </div>
        <div>
          <button className={styles.SubmitButton} type="submit">
            {activeTab}
          </button>
        </div>
        <div className={styles.Anonymous}>
          <button
            type="button"
            className="link"
            onClick={() => {
              signInAnonymously(auth);
            }}
          >
            Continue anonymously
          </button>
        </div>
      </div>
    </form>
  );
};

export default SignIn;
