import { onAuthStateChanged } from "firebase/auth";
import { onValue, ref, update } from "firebase/database";
import { useEffect, useState } from "react";
import Image from "./Image/Image";
import imageJSON from "../../data/images.json";
import typeData from "../../data/types.json";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { useRef } from "react";

// import tiers from "../../data/tiers.json";

// import { TierMenu } from "../../Modals/Dropdown";

const Images = ({
  database,
  auth,
  user,
  setUser,
  hideChecked,
  filter,
  sortBy = "tier",
}) => {
  const [data, setData] = useState();
  const [imageData, setImageData] = useState(imageJSON);
  const grid = useRef();

  // const [coords, setCoords] = useState([]);
  // const [currentTarget, setCurrentTarget] = useState(null);
  // const [tierMenuVisible, setTierMenuVisible] = useState(false);

  useEffect(() => {
    if (user) {
      onValue(ref(database, `users/${user}`), (snapshot) => {
        const snap = snapshot.val();
        setData(snap);
        setImageData(
          imageJSON?.map((el) => {
            const tier = snap?.[el.id]?.tier || "None";
            const description = snap?.[el.id]?.description || "";
            return {
              ...el,
              tier,
              description,
            };
          })
        );
      });
    }

    onAuthStateChanged(auth, async (u) => {
      setUser(u?.uid);
    });
  }, [auth, database, setUser, user]);

  const toggleImg = (e) => {
    //setCoords([e.clientY, e.clientX]);
    //if (tierMenuVisible && currentTarget !== e.target.id)
    //  setTimeout(() => {
    //    setTierMenuVisible(true);
    //  }, 1);
    //setTierMenuVisible(!tierMenuVisible);
    //console.log(e.target);
    //setCurrentTarget(e.target.id);
    //
    //return;

    const selected = e.target.classList.contains("toggled");
    const id = e.target.src.replace(/.*\/(.*)?\..*/, "$1");
    update(ref(database, `users/${user}/${id}`), {
      selected: !selected,
    });
  };

  const setTier = async ({ id, tier }) => {
    await update(ref(database, `users/${user}/${id}`), {
      tier,
    });
  };
  const setDescription = async ({ id, description }) => {
    await update(ref(database, `users/${user}/${id}`), {
      description,
    });
  };

  const buildImages = imageData
    .filter((image) => {
      if (!filter && !hideChecked) return true;
      return (
        !(hideChecked ? data?.[image.id]?.selected : false) &&
        (filter ? data?.[image.id]?.tier === filter : true)
      );
    })
    .sort((a, b) => {
      if (sortBy === "tier") {
        a[sortBy] = a[sortBy].replace(/^S/, ".S").replace("None", "_");
        b[sortBy] = b[sortBy].replace(/^S/, ".S").replace("None", "_");
      }
      if (a[sortBy] === b[sortBy]) return a.title > b.title ? 1 : -1;
      return a[sortBy] > b[sortBy] ? 1 : -1;
    })
    .map((image) => (
      <CSSTransition classNames="fade" key={image.src} timeout={500}>
        <Image
          {...image}
          id={image.id}
          selectedTier={data?.[image.id]?.tier}
          src={`/images/${image.src}`}
          alt={image.description}
          className={data?.[image.id]?.selected ? "toggled" : ""}
          title={image.title}
          onClick={toggleImg}
          onContextMenu={setTier}
          onDescription={setDescription}
          borderColor={typeData[image.type]}
        />
      </CSSTransition>
    ));

  return (
    <>
      <TransitionGroup ref={grid} className="images">
        {buildImages}
      </TransitionGroup>

      {/* <TierMenu
        visible={tierMenuVisible}
        options={tiers}
        onClick={() => {}}
        position={coords}
      /> */}
    </>
  );
};

export default Images;
