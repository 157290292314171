import "./App.css";

import SignIn from "./Modals/SignIn/SignIn";
import { deleteUser, getAuth } from "firebase/auth";
import { getDatabase, ref, remove } from "firebase/database";
import Images from "./components/Images/Images";
import { useState } from "react";
import { Link } from "react-router-dom";

function App() {
  const auth = getAuth();
  const [user, setUser] = useState(auth?.currentUser?.uid);
  const [hide, setHide] = useState(false);
  const [filter, setFilter] = useState(null);
  const [sortBy, setSortBy] = useState("type");
  const database = getDatabase();

  const signOut = async () => {
    if (auth.currentUser.isAnonymous) {
      remove(ref(database, `users/${user}`));
      return deleteUser(auth.currentUser);
    }
    auth.signOut();
  };

  return (
    <div className="App">
      <SignIn />
      <header>
        <div className="container">
          <div className="title">FoodViewer</div>
        </div>
        <div className="menu">
          <div className="filters">
            <label htmlFor="toggled">
              Hide disabled{" "}
              <input
                type="checkbox"
                name="toggled"
                id="toggled"
                className="filter"
                style={{ width: "1.25rem", height: "1.25rem" }}
                onChange={() => setHide(!hide)}
              />
            </label>

            <label htmlFor="filter">
              Filter
              <select
                name="filter"
                id="filter"
                className="filter"
                onChange={(e) => setFilter(e.target.value || null)}
              >
                <option value="">{filter ? "Clear" : "None"}</option>
                <option value="S">S</option>
                <option value="A">A</option>
                <option value="B">B</option>
                <option value="C">C</option>
                <option value="D">D</option>
                <option value="E">E</option>
                <option value="F">F</option>
              </select>
            </label>
            <label htmlFor="sortby">
              Sort by
              <select
                name="sortby"
                id="sortby"
                className="filter"
                onChange={(e) => setSortBy(e.target.value || "title")}
                defaultValue="type"
              >
                <option value="title">Name</option>
                <option value="type">Type</option>
                <option value="tier">Tier</option>
              </select>
            </label>
          </div>
          {user && (
            <div className="signout">
              <button onClick={signOut}>Logout</button>
            </div>
          )}
        </div>
      </header>
      <main>
        <div className="container">
          <Images
            auth={auth}
            database={database}
            setUser={setUser}
            user={user}
            hideChecked={hide}
            filter={filter}
            sortBy={sortBy}
          />
        </div>
      </main>
      <footer>
        <Link to="/legal">Legal</Link>
      </footer>
    </div>
  );
}

export default App;
