import { Link } from "react-router-dom";
import imageData from "./data/images.json";
import styles from "./Legal.module.css";
const Legal = () => {
  const attributes = imageData
    .sort((a, b) => (a.author > b.author ? 1 : -1))
    .map(({ src, originLink, author, authorLink }) => (
      <li className={styles.attrImage}>
        <a href={originLink} target="_blank" rel="noreferrer">
          <img src={`/images/${src}`} alt="" height={100} width={100} />
        </a>
        <div className={styles.author}>
          <a href={authorLink} target="_blank" rel="noreferrer">
            {author}
          </a>
        </div>
      </li>
    ));
  return (
    <div className="container">
      <div style={{ paddingTop: "50px" }}></div>
      <div style={{ marginBottom: "2em" }}>
        <Link to="/" className="buttonLink">
          Back
        </Link>
      </div>
      <div className={styles.attributes}>
        <h1>Attributes</h1>
        <ul>{attributes}</ul>
      </div>
      <div style={{ paddingBottom: "100px" }}></div>
    </div>
  );
};
export default Legal;
