import { useRef } from "react";
import { useState } from "react";
import { CSSTransition } from "react-transition-group";
import styles from "./Image.module.css";
const Image = ({
  src,
  alt,
  onContextMenu,
  onClick,
  className,
  title,
  id,
  selectedTier,
  borderColor,
  description,
  onDescription,
  ...props
}) => {
  const [active, setActive] = useState(false);
  const [DropdownLeft, setDropdownLeft] = useState(false);
  const [input, setInput] = useState(null);

  const dropdownRef = useRef(null);

  const handleDropdown = async (e) => {
    e.preventDefault();
    setActive(!active);
    setDropdownLeft(e.target.x + 500 > window.innerWidth);
  };

  const handleInput = (e) => {
    clearTimeout(input);
    setInput(
      setTimeout(() => {
        console.log(e.target.value);
        onDescription({ id, description: e.target.value });
      }, 300)
    );
  };

  const handleToggle = (e) => {
    if (active) return setActive(false);
    return onClick(e);
  };

  const tiers = [
    { tier: "S", backgroundColor: "#0ff" },
    { tier: "A", backgroundColor: "#4f4" },
    { tier: "B", backgroundColor: "#ce4" },
    { tier: "C", backgroundColor: "#ef4" },
    { tier: "D", backgroundColor: "#fa4" },
    { tier: "E", backgroundColor: "#f74" },
    { tier: "F", backgroundColor: "#f00" },
  ].map(({ tier, backgroundColor }) => (
    <div
      className={[styles.Tier, selectedTier === tier && styles.Selected].join(
        " "
      )}
      style={{ backgroundColor }}
      onClick={() => onContextMenu({ id, tier })}
      key={`${id}${tier}`}
    >
      {tier}
    </div>
  ));

  return (
    <div
      className={styles.ImageContainer}
      onMouseLeave={() => setActive(false)}
    >
      <img
        id={id}
        onContextMenu={handleDropdown}
        onClick={handleToggle}
        src={src}
        alt={alt}
        className={`${styles.Image} ${className}`}
        style={{
          borderColor: borderColor || "#0000",
        }}
      />
      <div className={styles.ImageTitle}>{title}</div>

      <CSSTransition
        nodeRef={dropdownRef}
        in={active}
        mountOnEnter={true}
        unmountOnExit={true}
        timeout={1000}
        classNames="grow"
      >
        <div
          className={`${styles.Dropdown} ${
            DropdownLeft ? styles.DropdownLeft : ""
          }`}
          ref={dropdownRef}
        >
          <div
            style={{ display: "flex", flexDirection: "column", gap: ".5em" }}
          >
            {tiers}
          </div>
          <div>
            <textarea
              className={styles.Description}
              name="description"
              id="description"
              placeholder="Description"
              defaultValue={description}
              style={{ padding: "1em" }}
              onChange={handleInput}
              rows="10"
              cols="30"
            ></textarea>
          </div>
        </div>
      </CSSTransition>
    </div>
  );
};

export default Image;
